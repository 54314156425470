import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { FaMicrosoft } from "react-icons/fa";

const Automation = () => (
  <React.Fragment>
    <Helmet>
      <title>
        Smart Home Automation and Motorisation | Melbourne | Brisbane | Birk
      </title>
      <meta
        property="og:title"
        content="Smart Home Automation and Motorisation | Melbourne | Brisbane | Birk"
      />
      <meta
        name="keywords"
        content="Roller Blinds, Roman Blinds, Curtains, Shutters, Venetians, External Range, Automation, Honeycomb Blinds"
      />
      <meta
        name="description"
        content="Birk offers range of Smart Home Automation and Motorisation. We are operated in Melbourne and Brisbane"
      />
      <meta
        property="og:description"
        content="Birk offers range of Smart Home Automation and Motorisation. We are operated in Melbourne and Brisbane"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="http://birk.itechnotion.com/automation"
      />
      <link rel="canonical" href="http://birk.itechnotion.com/automation" />
    </Helmet>
    <Header />
    <section className="product-section">
      <div className="container-fluid all-section">
        <div className="row">
          <div className="col-md-4 col-lg-3">
            <div className="heading">
              <h2>Automation</h2>
            </div>
            <div className="product-menu">
              <ul>
                <li className="mb-2">
                  <Link to="/roller-blinds">Roller Blinds</Link>
                </li>
                <li className="mb-2">
                  <Link to="/roman-blinds">Roman Blinds</Link>
                </li>
                <li className="mb-2">
                  <Link to="/curtains">Curtains</Link>
                </li>
                <li className="mb-2">
                  <Link to="/shutters">Shutters</Link>
                </li>
                <li className="mb-2">
                  <Link to="/venetians">Venetians</Link>
                </li>
                <li className="mb-2">
                  <Link to="/external-range">External Range</Link>
                </li>
                <li className="mb-2">
                  <Link to="/automation">
                    <span className="font-weight-bold">Automation</span>
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/honeycomb">Honeycomb</Link>
                </li>
              </ul>
              <div className="links-list sidebar-links">
                <Link to="/gallery" className="links-list-item">
                  <FaMicrosoft />
                  &nbsp; Go to gallery
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-8 col-lg-9 product-item mt-2">
            <div className="col-12 col-md-12 mb-4">
              <div className="pro-start-content">
                <div className="product-inner">
                  <h4 className="text-justify">
                    SMART HOME AUTOMATION AND MOTORISATION
                  </h4>
                  <p className="text-justify">
                    Unlock the full potential of motorised window coverings
                    through automation, operate a window covering via a remote
                    control, sensor, smartphone, tablet or even a 3rd party
                    home-automation system.
                  </p>
                  <p className="text-justify">
                    Remote control operation provides convenience and comfort
                    allowing complete control of your internal blind or external
                    blinds without having to physically operate it yourself. As
                    our range of motorised window coverings have no cords -
                    child safety concerns are reduced when picking motorised
                    products and are a natural choice for families of all sizes.
                    Motorised products can be powered by batteries for easy
                    installation, eliminating the need for an electrician; or
                    hard-wired to 240V AC, providing a constant power without
                    the need to replace batteries. Ask us how easy it is to
                    motorise and save on energy bills, and ultimately, the
                    environment.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-5 col-sm-12 mt-2 mb-4">
              <div className="d-flex flex-wrap">
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/automation-icon1.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">Battery</p>
                </div>
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/automation-icon2.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">240v</p>
                </div>
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/automation-icon3.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">
                    Smart Phone
                    <br />
                    Integration
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 mb-4">
              <StaticImage
                src="../images/bautomation1.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </React.Fragment>
);
export default Automation;
